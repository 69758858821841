import type React from 'react';

import {type MoTEvent, type ReactMoTEvent} from './interface';

const isTouchEvent = (e: MoTEvent | ReactMoTEvent): e is React.TouchEvent | TouchEvent =>
  !!(e as TouchEvent).touches;

export const getPageX = (e: MoTEvent | ReactMoTEvent) =>
  isTouchEvent(e) ? e.touches[0].pageX : e.pageX;

export const MoTEventPreHandler = (
  e: MoTEvent | ReactMoTEvent,
  returnPageX?: true
): {isTouchEvent: boolean; pageX: number} => {
  const ite = isTouchEvent(e);

  if (!ite) e.preventDefault();
  e.stopPropagation();

  return {isTouchEvent: ite, pageX: !returnPageX ? 0 : getPageX(e)};
};

export const handleMove = (
  pageX: number,
  duration: number,
  progress: HTMLDivElement | null,
  cb: (value: number) => void
) => {
  if (!progress) {
    return;
  }
  const {left, right, width} = progress.getBoundingClientRect();
  if (pageX < left) {
    return cb(0);
  }
  // set value to duration -.5s, there was a bug with setting whole duration - occasionally
  // moving dragging cursor to the right of right bound of progress could cause stop action,
  // which is legit, but not desirable behavior.
  cb(pageX > right ? duration - 0.5 : ((pageX - left) / width) * duration);
};

export const handleProgressClick = (
  e: React.MouseEvent<HTMLDivElement>,
  duration: number,
  progress: HTMLDivElement | null,
  cb: (value: number) => void
) => {
  e.preventDefault();
  e.stopPropagation();
  if (progress) {
    const {left, width} = progress.getBoundingClientRect();
    cb(((e.pageX - left) / width) * duration);
  }
};
