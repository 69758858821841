import {defineMessages} from 'react-intl';

import {MediaContextAction} from '../interface';

export const errorMessages = defineMessages({
  [MediaContextAction.Play]: {
    id: 'File.PlayMediaTogetherError'
  },
  [MediaContextAction.Pause]: {
    id: 'File.PauseMediaTogetherError'
  },
  [MediaContextAction.Stop]: {
    id: 'File.StopMediaTogetherError'
  },
  [MediaContextAction.ChangeTimestamp]: {
    id: 'File.ChangeMediaTimestampTogetherError'
  },
  [MediaContextAction.ChangeRate]: {
    id: 'File.PlayMediaTogetherError'
  }
});
