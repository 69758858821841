import React, {type FC, useCallback, useMemo} from 'react';
import {type TooltipProps} from 'rc-tooltip/lib/Tooltip';
import {useIntl} from 'react-intl';
import classNames from 'classnames';

import Icon from 'components/Icon';
import DropDown from 'components/DropDown';

import './PlaybackRate.scss';

const playbackRates = {
  0.5: '0.50',
  0.75: '0.75',
  1: '1.00',
  1.25: '1.25',
  1.5: '1.50'
};

export const PlaybackRate: FC<{
  buttonClassName?: string;
  getTooltipContainer?(): HTMLElement;
  playbackRate?: number;
  placement?: TooltipProps['placement'];
  changePlaybackRate(playbackRate: number): void;
  onVisibleChangeCallback?(visible?: boolean): void;
}> = ({
  getTooltipContainer,
  buttonClassName,
  playbackRate,
  changePlaybackRate,
  onVisibleChangeCallback,
  placement = 'bottom'
}) => {
  const intl = useIntl();

  const items = useMemo(
    () =>
      Object.values(playbackRates)
        .sort()
        .reduce((a, v) => ({...a, [v]: `${intl.formatMessage({id: 'Common.Speed'})} ${v}`}), {}),
    [intl]
  );

  const onChange = useCallback(
    (key: string) => {
      if (!Object.values(playbackRates).includes(key)) return;
      changePlaybackRate(+key);
    },
    [changePlaybackRate]
  );

  return (
    <DropDown
      getTooltipContainer={getTooltipContainer}
      className="rc-dropdown playback-rate-dropdown"
      onVisibleChangeCallback={onVisibleChangeCallback}
      overlayClassName="rc-dropdown-overlay playback-rate-overlay"
      activeKey={playbackRate && playbackRates[playbackRate] ? playbackRates[playbackRate] : '1.00'}
      onChange={onChange}
      toggleClassName={classNames('rc-dropdown-toggle', buttonClassName)}
      items={items}
      value={<Icon name="dashboard" />}
      noCaret={true}
      trigger={['click']}
      placement={placement}
    />
  );
};
